import React, { useState, FormEvent } from "react";

import "./styles.css";

import Input from "../../components/Input";
import loadingGif from "../../assets/images/loading.svg";

import api from "../../services/api";

function Landing() {
  const [email, setEmail] = useState("");
  const [sending, setSending] = useState(false);
  const [notified, setNotified] = useState(false);
  const [errorSending, setErrorSending] = useState(false);

  async function notify(e: FormEvent) {
    e.preventDefault();
    setErrorSending(false);
    setSending(true);

    const response: any = await api
      .post("minepag-emails.php", { email })
      .catch(() => null);

    setSending(false);

    if (response && response.data.status === "success") {
      setNotified(true);
      setEmail("");
    } else {
      setErrorSending(true);
    }
  }

  return (
    <div id="page-landing">
      <h1>MinePag</h1>

      <div id="page-landing-content">
        <div className="container">
          <h2>EM BREVE</h2>
          <h3>Se inscreva para que seja notificado quando lançarmos</h3>
          <form className="buttons-container" onSubmit={notify}>
            <Input
              type="email"
              required
              name="time"
              placeholder="Endereço de e-mail"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />

            <button type="submit" disabled={sending}>
              {sending && (
                <img src={loadingGif} alt="Carregando" className="loading" />
              )}
              Me Notifique!
            </button>
          </form>

          {notified && (
            <p className="notified">
              Anotado! Você receberá a nossa notificação no lançamento!
            </p>
          )}

          {errorSending && (
            <p className="error-sending">
              Ops! Parece que houve um erro! Poderia tentar novamente?
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Landing;
